var crossDomainGl = {
    getCrossDomainGlIncludeUrl : function(url) {
        var urlQueryStrings = window.location.search;
        if (url !== '') {
            if (url.indexOf('?') !== -1) {
                if (urlQueryStrings !== '') {
                    urlQueryStrings = urlQueryStrings.replace('?', '&');
                }
            }
            url += urlQueryStrings;
            var checkParam = this.getUrlSearchParam('_gl');
            if (checkParam === '') {
                if (url.indexOf('?') !== -1) {
                    url += '&';
                } else {
                    url += '?';
                }
                url += '_gl=' + this.getCrossDomainGl();
            }
        }
        return url;
    },
    getOnlyCrossDomainGlIncludeUrl : function(url) {
        var googleTagData = crossDomainGl.getUrlSearchParam('_gl');
        if (googleTagData === '') {
            googleTagData = this.getCrossDomainGl();
        }
        if (url.indexOf('?') !== -1) {
            url += '&';
        } else {
            url += '?';
        }
        url += '_gl=' + googleTagData;
        return url;
    },
    getCrossDomainGl : function() {
        var gtagId = "G-ZBLV8D536G";

        try {
            if (window.google_tag_data && window.google_tag_data.glBridge) {
                var mId = gtagId.replace('G-', '');
                var currentGaCookie = lgkorUI.getCookie('_ga');
                var currentGaMIdCookie = lgkorUI.getCookie('_ga_' + mId);
                var gaCookie = Array.isArray(currentGaCookie) ? currentGaCookie[0] : currentGaCookie;
                var gaMIdCookie = Array.isArray(currentGaMIdCookie) ? currentGaMIdCookie[0] : currentGaMIdCookie;
                var cookieObject = {
                    _ga: gaCookie.substring(6),
                }
                cookieObject['_ga_' + mId] = gaMIdCookie.substring(6);
                var gl = window.google_tag_data.glBridge.generate(cookieObject);
                if (gl) {
                    window.dataLayer.push({
                        event: 'gl_ready',
                        gtagApiResult: {
                            _gl: gl,
                        },
                    })
                }
                return gl;
            }
        } catch (error) {
            void 0;
            return '';
        }
    },
    goUrl:  function(url) {
        if (url !== '') {
            location.href = this.getCrossDomainGlIncludeUrl(url);
        }
    },
    getUrlSearchParam: function(name) {
        var svalue = "";
        var curr_url = "";
        var urlQueryStrings = window.location.search;
        if (urlQueryStrings !== '') {
            curr_url = urlQueryStrings.substring(1);
            curr_url = curr_url.split("&");
            for (var i = 0; i < curr_url.length; i++) {
                temp = curr_url[i].split("=");
                if ([temp[0]] == name) {
                    svalue = temp[1];
                }
            }
        }

        return svalue;
    }

}